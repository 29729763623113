class ZIDXAccountListingIndexFiles implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingIndexFilesContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/file";
    }
    render(){
        // console.log("listing index file edit");
    }
}